<template>
  <div class="visit-x">
    <div class="girl-row">
      <div v-for="girl in data" :key="`girl${girl.id}`" class="girl-column">
        <GirlPreview :girl="girl" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GirlPreview from "@/components/GirlPreview";

export default {
  name: "VisitXWebComponent",
  props: {
    baseUrl: {
      type: String
    },
    campaignId: {
      type: String
    }
  },
  components: {
    GirlPreview
  },
  data() {
    return {
      data: []
    };
  },
  created() {
    axios
      .get("http://v-cash-api.eve-paris.com")
      .then(({ data }) => {
        this.data = data.data;
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>

<style lang="scss">
.visit-x {
  box-sizing: border-box;
  .girl-row {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;

    .girl-column {
      box-sizing: border-box;
      width: 25%;
      padding: 0.75rem;
      flex: none;
      display: block;

      .swiper-button-next,
      .swiper-button-prev {
        color: #fff;
      }

      @media screen and (max-width: 769px) {
        width: 50%;
      }

      @media screen and (max-width: 640px) {
        width: 100%;
      }
    }
  }
}
</style>