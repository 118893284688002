<template>
  <div class="girl-preview" :class="`girl${girl.id}`">
    <a :href="girlUrl" target="_blank">
      <swiper :options="swiperOptions" ref="swiper">
        <swiper-slide v-for="photo in girl.albumPictures" :key="photo">
          <img :src="photo" alt />
        </swiper-slide>
      </swiper>
      <strong>{{girl.login}}</strong>
      <br />
      {{girl.motto}}
    </a>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "GirlPreview",
  props: {
    girl: {
      tpye: Object
    },
    baseURL: {
      type: String,
      default: "https://livegirls.eve-paris.com"
    },
    campaignId: {
      type: String,
      default: "2005001"
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 40,
        autoplay: true
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    girlUrl() {
      return `${this.baseURL}/${this.girl.profile}&w=45813&ws=${this.campaignId}`;
    }
  }
};
</script>

<style lang="scss">
@import "swiper/css/swiper.min";
@font-face {
  font-family: "swiper-icons";
  src: url("/fonts/swiper-icons.ttf");
}
.girl-preview {
  position: relative;

  a {
    text-decoration: none;
    color: #fff;

    strong {
      display: block;
      margin: 5px 0 -7px 0;
    }
  }

  img {
    width: 100%;
  }
}
</style>